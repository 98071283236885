import React, { createContext } from 'react';
import {
  HashRouter,
  Switch,
  Route,
  useParams,
  useLocation
} from 'react-router-dom';
import { Global } from '@emotion/core';
import ResetStyled from './style/Reset.Styled';
import ResetLuckyStyled from './style/ResetLucky.Styled';
import ResetEnFrStyled from './style/ResetTranslation.Styled';
import ResetEnergyStyled from './style/ResetEnergy.Styled';
import ChargeUsageResetStyled from './style/ResetChargeUsage.Styled';

import BaseStyled from './style/Base.Styled';

import Main from './pages/Main';
import EnMain from './pages/EnMain';
import FrMain from './pages/FrMain';
import Policy from './pages/Policy';
import YescoEvent from './pages/YescoEvent';
import BenenuryEvent from './pages/BenenuryEvent';
import EnergyBill from './pages/EnergyBill';
import GasFeeSupport from './pages/GasFeeSupport';
import NaverGateCash from './pages/NaverGateCash';
import NaverGateLiveBill from './pages/NaverGateLiveBill';
import NaverGateEco from './pages/NaverGateEco';
import NaverGateMeter from './pages/NaverGateMeter';
import NaverGateRelocation from './pages/NaverGateRelocation';
import CelebrationIC from './pages/CelebrationIC';
import CelebrationYESCO from './pages/CelebrationYESCO';
import CelebrationJB from './pages/CelebrationJB';
import CelebrationKS from './pages/CelebrationKS';
import CelebrationKTRM from './pages/CelebrationKTRM';
import CelebrationCV from './pages/CelebrationCV';
import Survey from './pages/Survey';

import Test from './pages/Test';
import Youtube from './pages/Youtube';
import ChargeUsage from './pages/ChargeUsage';
import LuckyMarket from './pages/LuckyMarket';
import SummerEvent from './pages/SummerEvent';
import HanBillInviteEvent from './pages/HanBillInviteEvent';
import HanBillInviteEventCommon from './pages/HanBillInviteEventCommon';
import LuckyGate from './pages/LuckyGate';
import WinterEventList from './pages/WinterEventList';
import WinterEventEco from './pages/WinterEventEco';
import WinterEventElec from './pages/WinterEventElec';
import WinterEventHeating from './pages/WinterEventHeating';
import SettlementReceipt from './pages/SettlementReceipt';
import LuckyRoulette from './pages/LuckyRoulette';

import MemberLeave from './pages/MemberLeave';

import '@gasapp/ui/dist/style.css';
import ResetGasAppMobileStyled from './style/ResetGasAppMobile.Styled';

const ScgLab = () => {
  const { pathname } = useLocation();

  const defaultStyled = [ResetStyled, BaseStyled];
  const styledMap = {
    '/en': [ResetEnFrStyled, BaseStyled],
    '/fr': [ResetEnFrStyled, BaseStyled],

    '/energybill': [ResetEnergyStyled, BaseStyled],
    '/gasFeeSupport': [ResetEnergyStyled, BaseStyled],
    '/liveBill': [ResetEnergyStyled, BaseStyled],
    '/cash': [ResetEnergyStyled, BaseStyled],
    '/lucky': [ResetEnergyStyled, BaseStyled],
    '/survey': [ResetEnergyStyled, BaseStyled],

    '/chargeUsage': [ChargeUsageResetStyled, BaseStyled],
    '/luckyMarket': [ResetLuckyStyled],
    '/summerEvent': [ResetLuckyStyled],
    '/hanBillInviteEvent': [ResetLuckyStyled],
    '/settlementReceipt': [ResetLuckyStyled],
    '/memberLeave': [ResetGasAppMobileStyled],
    '/celebrationIC': [ResetGasAppMobileStyled],
    '/celebrationYESCO': [ResetGasAppMobileStyled],
    '/celebrationJB': [ResetGasAppMobileStyled],
    '/celebrationKS': [ResetGasAppMobileStyled],
    '/celebrationKTRM': [ResetGasAppMobileStyled],
    '/celebrationCV': [ResetGasAppMobileStyled]
  };

  return <Global styles={styledMap[pathname] || defaultStyled} />;
};

export default function App() {
  return (
    <HashRouter>
      <main>
        <ScgLab />
        <Switch>
          <Route path={`/`} exact children={<Main />} />
          <Route path={`/Policy`} children={<Policy />} />
          <Route path={`/en`} exact children={<EnMain />} />
          <Route path={`/fr`} exact children={<FrMain />} />

          <Route path={`/yescoevent`} exact children={<YescoEvent />} />
          <Route path={`/benenuryevent`} exact children={<BenenuryEvent />} />
          <Route path={`/energybill`} exact children={<EnergyBill />} />
          <Route path={`/gasFeeSupport`} exact children={<GasFeeSupport />} />
          <Route path={`/youtube`} exact children={<Youtube />} />
          <Route path={`/cash`} children={<NaverGateCash />} />
          <Route path={`/liveBill`} exact children={<NaverGateLiveBill />} />
          <Route path={`/eco`} exact children={<NaverGateEco />} />
          <Route path={`/meter`} exact children={<NaverGateMeter />} />
          <Route path={`/celebrationIC`} exact children={<CelebrationIC />} />
          <Route
            path={`/celebrationYESCO`}
            exact
            children={<CelebrationYESCO />}
          />
          <Route path={`/celebrationJB`} exact children={<CelebrationJB />} />
          <Route path={`/celebrationKS`} exact children={<CelebrationKS />} />
          <Route
            path={`/celebrationKTRM`}
            exact
            children={<CelebrationKTRM />}
          />
          <Route path={`/celebrationCV`} exact children={<CelebrationCV />} />
          <Route
            path={`/relocation`}
            exact
            children={<NaverGateRelocation />}
          />
          <Route path={`/lucky`} children={<LuckyGate />} />
          <Route path={`/survey`} children={<Survey />} />

          <Route path={`/test`} exact children={<Test />} />
          <Route path={`/chargeUsage`} exact children={<ChargeUsage />} />
          <Route path={`/luckyMarket`} exact children={<LuckyMarket />} />
          <Route path={`/summerEvent`} exact children={<SummerEvent />} />
          <Route
            path={`/hanbillInviteFriends`}
            exact
            children={<HanBillInviteEvent />}
          />
          <Route
            path={`/hanbillInvite`}
            exact
            children={<HanBillInviteEventCommon />}
          />
          <Route
            path={`/winterEventList`}
            exact
            children={<WinterEventList />}
          />
          <Route path={`/winterEventEco`} exact children={<WinterEventEco />} />
          <Route
            path={`/winterEventElec`}
            exact
            children={<WinterEventElec />}
          />
          <Route
            path={`/winterEventHeating`}
            exact
            children={<WinterEventHeating />}
          />
          <Route
            path={`/settlementReceipt`}
            exact
            children={<SettlementReceipt />}
          />
          <Route path={`/luckyRoulette`} exact children={<LuckyRoulette />} />
          <Route path={`/memberLeave`} exact children={<MemberLeave />} />
        </Switch>
      </main>
    </HashRouter>
  );
}
