import { css } from '@emotion/core';
import ResetStyled from './Reset.Styled';
import { SuiteFont } from './Fonts.Styled';

const ResetLuckyStyled = css`
  ${ResetStyled}
  html {
    font-size: 15px;
  }
  ${SuiteFont}
`;
export default ResetLuckyStyled;
